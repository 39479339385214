import React from 'react';
import './objetivos.css';

// Directly import the images
import ods1 from '../../static/objetivos/logo-ODS1.png';
import ods4 from '../../static/objetivos/logo-ODS4.png';
import ods5 from '../../static/objetivos/logo-ODS5.png';
import ods8 from '../../static/objetivos/logo-ODS8.png';
import ods10 from '../../static/objetivos/logo-ODS10.png';
import ods17 from '../../static/objetivos/logo-ODS17.png';

function Objetivos() {
  const odsImages = [ods1, ods4, ods5, ods8, ods10, ods17];

  return (
    <>
      <h2>Nuestros objetivos actuales son:</h2>
      <div id="nos_ods">
        <ul>
          {odsImages.map((image, index) => (
            <li key={index}>
              <img src={image} alt={`Objetivo ${index + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Objetivos;
