import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      textAlign: 'center',
    }}>
      <h1>404 - Página No Encontrada</h1>
      <p>La página que estás buscando no existe.</p>
      <Link to="/">
        <button style={{ padding: '10px 20px', fontSize: '16px' }}>Volver a la Página Principal</button>
      </Link>
    </div>
  );
};

export default NotFound;
