import React from 'react';
import Linktree from '../components/6. Linktree/linktree';

const componentMapping = {
  linktree: Linktree,
};

const LinkTree = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default LinkTree;
