// 1. Imports
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './novedades.css';

// 2. Novedades Component - State and Helper Functions
function Novedades() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(8);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // 3. Fetch Data from API
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(false);
      try {
        const fileName = location.pathname.includes('/noticias') ? 'noticias.json' : 'testimonios.json';
        const response = await fetch(`https://redmaestra.blob.core.windows.net/frontend/${fileName}`);
        if (!response.ok) throw new Error('Failed to fetch data');
        
        const rawData = await response.json();
        const sortedData = rawData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Error loading data:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [location]);

  // 4. Filter Data based on Search and Year
  useEffect(() => {
    const normalizeText = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    const filterData = () => {
      let filtered = data;
      if (searchTerm) {
        const normalizedSearchTerm = normalizeText(searchTerm);
        filtered = filtered.filter(item => 
          normalizeText(item.title).includes(normalizedSearchTerm) || 
          (item.body && normalizeText(item.body).includes(normalizedSearchTerm))
        );
      }
      if (selectedYear) {
        filtered = filtered.filter(item => extractYearFromDate(item.date) === selectedYear);
      }
      setFilteredData(filtered);
    };
    filterData();
  }, [data, searchTerm, selectedYear]);

  // Helper Functions
  const extractYearFromDate = (date) => date.split('-')[0];
  const getImageUrl = (item) => item.image ? item.image : 'https://redmaestra.blob.core.windows.net/media/default.png';

  // 5. Handlers for UI Interactions
  const handleYearSelection = (event) => setSelectedYear(event.target.value);
  const showAllData = () => {
    setSearchTerm('');
    setSelectedYear('');
    setFilteredData(data);
    setVisibleCount(8);
  };
  const loadMore = () => setVisibleCount(prevCount => prevCount + 8);

  // 6. Year Options Based on Page
  const years = location.pathname.includes('/noticias') ? ["2023", "2022", "2021", "2020", "2019"] : ["2023", "2022", "2021", "2020"];

  // 7. Render Logic for Loading, Error, and Data Display
  if (isLoading) return <div className="loading-placeholder"><p>Procesando...</p></div>;
  if (error || !data.length) return <div className="loading-placeholder"><p>Procesando... Intente más tarde</p></div>;

  // Format the date in "dd de month de yyyy" without changing it due to timezone issues
const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  return `${parseInt(day)} de ${months[parseInt(month) - 1]} de ${year}`;
};

  return (
    <div id="novedades">
      <div className="filters">
        <input type="text" className="search-bar" placeholder="Buscar..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <div className="year-selector">
          <select className="year-dropdown" value={selectedYear} onChange={handleYearSelection}>
            <option value="">Todas</option>
            {years.map(year => <option key={year} value={year}>{year}</option>)}
          </select>
        </div>
        <button className="btn btn-primary reset-button" onClick={showAllData}>Resetear</button>
      </div>
      <div className="novedades">
      {filteredData.slice(0, visibleCount).map(item => (
        <div key={item.id} className="novedades-item">
        <div>
          <img src={getImageUrl(item)} alt={item.title} className={`${location.pathname.includes('/testimonios') ? 'testimonio' : 'noticia'}`}/>
          <p className="date">{formatDate(item.date)}</p>
          <hr/>
          <p className="">{item.title}</p>
        </div>
          <Link to={`/${location.pathname.includes('/noticias') ? 'noticias' : 'testimonios'}/${item.id}`} className="btn btn-primary bl">Leer más</Link>
        </div>
      ))}
      </div>
      {visibleCount < filteredData.length && <button onClick={loadMore} className="btn btn-primary bl lm">Mostrar más</button>}
    </div>
  );
}

export default Novedades;
