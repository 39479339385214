import React from 'react';
import './conv_pred.css';

function ConvPred() {

    return (
        <>
              <div className="card cconvid">
                <div className="conv-logos">
                <img src='https://redmaestra.blob.core.windows.net/frontend/Otros/logo-lipigas-blanco.png' alt="logo-lipigas"/>
                <img src='https://redmaestra.blob.core.windows.net/static/images/comunas/Santiago.png' alt="logo-stgo"/>
                
                </div>
                <h2>Técnicas de Gasfitería e Instalaciones Sanitarias</h2>
                <h5>Santiago</h5>
                <span>Inicio:</span>
                <p>Enero 2025</p>
                <span>Horario:</span>
                <p>Jornada AM</p>
                <span>Sede:</span>
                <p>Inacap Maipú</p>
                <span>Requisitos:</span>
                <ul>
                <li>Mujeres mayores de 18 años.</li>
                <li>Vivir en la comuna de Maipú.</li>
                <li>Carnet de Identidad Vigente.</li>
                </ul>
                <a href="https://redmaestra.azurewebsites.net/api/seleccion/agregar_postulante_proceso/12/" className="btn btn-primary bl">Postular</a>
              </div>
        </>
    );
};

export default ConvPred;

