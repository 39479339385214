import React, { useState } from 'react';
import './programas.css';

// Directly import the images
import maestra from '../../static/icons/maestra2.png';
import academica from '../../static/icons/academica.png';
import cajaHerramientas from '../../static/icons/icono-caja-herramientas-ama.png';
import comunitaria from '../../static/icons/comunitaria.png';
import empresa from '../../static/icons/empresa.png';
import figuraA from '../../static/icons/figura a.png';
import capacitacion from '../../static/icons/capacitacion.png';
import mujer1 from '../../static/icons/mujer (1).png';
import mujer2 from '../../static/icons/mujer (2).png';
import programa1 from '../../static/programas/1.png';
import programa2 from '../../static/programas/2.png';
import programa3 from '../../static/programas/3.png';
import programa4 from '../../static/programas/4.png';
import programa5 from '../../static/programas/5.png';
import programa6 from '../../static/programas/6.png';
import programa7 from '../../static/programas/7.png';
import programa8 from '../../static/programas/8.png';
import programa9 from '../../static/programas/9.png';
import programa10 from '../../static/programas/10.png';

function Programa() {
  const [selectedImage, setSelectedImage] = useState(null);

  // Define an array of all the imported images
  const images = [
    maestra, academica, cajaHerramientas, comunitaria, empresa, figuraA, 
    capacitacion, mujer1, mujer2, 
    programa1, programa2, programa3, programa4, 
    programa5, programa6, programa7, programa8, 
    programa9, programa10
  ];

  const handleImageClick = (src) => {
    setSelectedImage(src);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div id="prog_showcase">
        <img src={images[0]} alt="Maestra" />
        <div id="prog_title">
          <h2>¿Cómo son los programas RedMaestra?</h2>
          <p>Son trabajos colaborativos con organizaciones para potenciar las competencias de un grupo de mujeres a través de capacitaciones integrales en oficios de la construcción, convirtiéndolas en grandes maestras.</p>
        </div>
      </div>

      <h1>¿Para quienes están dirigidos?</h1>

      <div className="programas_block">
        <ul>
          <li>
            <img src={images[1]} alt="Icono Academia" />
            <p>Organizaciones interesadas en <span>desarrollar mano de obra femenina en un sector productivo</span></p>
          </li>

          <li>
            <img src={images[2]} alt="Icono Caja" />
            <p>Organizaciones interesadas en <span>potenciar a sus colaboradoras o aumentar su dotación</span></p>
          </li>

          <li>
            <img src={images[3]} alt="Icono Redes" />
            <p>Organizaciones interesadas en <span>formar alianzas para alcanzar objetivos estratégicos</span></p>
          </li>
        </ul>
      </div>

      <div id="prog">
        <h1>¿Qué rol cumple cada uno?</h1>
        <div id="prog_left">
          <div>
            <h2>1. Organizaciones</h2>
            <p>Son empresas, municipalidades o fundaciones que buscan generar impactos positivos en comunidades o grupos de interés a través del desarrollo profesional y personal de las mujeres.</p>
            <p>Son el principal ente financiador de los programas.</p>
            <p>Dentro de las motivaciones que han llevado a organizaciones a trabajar con nosotras se destacan:</p>
            <ul>
              <li><p>Interés por aumentar su Responsabilidad Social Empresaria o cumplir con uno o más de los Objetivos de Desarrollo Sostenible</p></li>
              <li><p>Necesidad de desarrollar mano de obra femenina específica calificada en el mercado laboral o alcanzar ciertos estándares, certificaciones y/o reconocimientos</p></li>
            </ul>
          </div>
          <img src={images[5]} alt="Icono Figa" id="prog_figa" />
          <img src={images[4]} alt="Icono Emp" id="prog_fig1" />
        </div>

        <div id="prog_right">
          <img src={images[5]} alt="Icono Figb" id="prog_figb" />
          <img src={images[6]} alt="Icono Cap" id="prog_fig2" />
          <div>
            <h2>2. RedMaestra</h2>              
            <p>Somos el articulador que integra a todos los actores de un ecosistema necesarios para un propósito común.</p>
            <p>Dentro de nuestras fortalezas destacamos:</p>
            <ul>
              <li><p>Amplia experiencia capacitando integramente a mujeres para convertirlas en maestras en oficios</p></li>
              <li><p>Aseguramos que los esfuerzos y trabajos colaborativos generen un impacto que logre transformar la vida de las participantes</p></li>
              <li><p>Trabajamos día a día para contar con una gran red de contactos y a su vez visibilizar los resultados a través de los medios de comunicación</p></li>
            </ul>
          </div>
        </div>
      </div>

      <h1>¿Cuál es el impacto generado?</h1>

      <div className="impacto">
        <img src={images[7]} className="left-image" alt="LeftImp"/>
        <div>
          <p>Chile hoy vive una cruda realidad: existe un sin fin de mujeres que quieren surgir y maestras en “tierra de nadie”, a lo largo de todo el país.</p>

          <p style={{ fontWeight: 'bold' }}>
            A través de nuestros programas logramos generar en las participantes:
            <p><br/>
              <span>Autonomía Económica</span><br/>
              <span>Reconversión Laboral</span><br/>
              <span>Aumento de su Bienestar</span>
            </p>
          </p>

          <p>Desde RedMaestra, sostenemos con firmeza la creencia de que aún existen ideas y metodologías revolucionarias capaces de impulsar cambios concretos en beneficio de nuestra sociedad.</p>
        </div>
        <img src={images[8]} className="right-image" alt="RightImp"/>
      </div>

      <div className="blocks" id="prog_gallery">
        <ul>
          {/* Display first 5 images from 'programas' */}
          {images.slice(9, 14).map((src, index) => (
            <li key={index}><img src={src} alt={`P${index + 1}`} onClick={() => handleImageClick(src)} /></li>
          ))}
        </ul>
        <ul>
          {/* Display next 5 images from 'programas' */}
          {images.slice(14, 19).map((src, index) => (
            <li key={index}><img src={src} alt={`P${index + 6}`} onClick={() => handleImageClick(src)} /></li>
          ))}
        </ul>
      </div>
      {selectedImage && (
        <div className="expanded-image-overlay" onClick={handleClose}>
          <img src={selectedImage} alt="Expanded View" className="expanded-image" onClick={e => e.stopPropagation()} />
        </div>
      )}
    </>
  );
}

export default Programa;
