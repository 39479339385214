import React from 'react';
import Programa from '../components/3. Programas/programas';

const componentMapping = {
  programas: Programa,
};

const Programas = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default Programas;
