import React from 'react';
import './footer.css';

// Directly import the icons
import facebookIcon from '../../static/media-icons/logo-facebook.png';
import instagramIcon from '../../static/media-icons/logo-instagram.png';
import linkedinIcon from '../../static/media-icons/logo-linkedin.png';

function Footer() {

  return (
    <footer className="footer" id="footer">
      <div className="footcontent">
        <ul>
          <li id="footleft">
            <p>Síguenos en nuestras<span> Redes Sociales</span></p>
            <div>
              <ul>
                <li><a href="https://www.linkedin.com/company/redmaestra/" target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="LinkedIn"/></a></li>
                <li><a href="https://instagram.com/redmaestracl/" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram"/></a></li>
                <li><a href="https://www.facebook.com/redmaestracl/" target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="Facebook"/></a></li>
                {/*<li><a href="https://api.whatsapp.com/send?phone=56950951835&text=Hola!" target="_blank" rel="noopener noreferrer"><img src={whatsappIcon} alt="WhatsApp"/></a></li>*/}
              </ul>
            </div>
          </li>

          <li id="footright">
            <p>Para cualquier otro requerimiento o consulta <span>¡escríbenos!</span></p>
            <p style={{ fontWeight: "bold" }}><a href="mailto:contacto@redmaestra.cl" style={{ color: "white" }}>contacto@redmaestra.cl</a></p>
          </li>
        </ul>
      </div>

      <div className="footlegal">
        <p>Todos los derechos reservados, 2024.</p>
      </div>
    </footer>
  );
}

export default Footer;
