import React from 'react';
import './nosotras.css';

// Directly import the background image
import backgroundImage from '../../static/nosotras/motivacion.png';

function Nosotras() {
  return (
    <>
      <h1>Sobre Nosotras</h1>
      <div id="main_nosotras">
        <div id="nosotras_left">
          <p style={{ fontWeight: 600 }}>¿Qué es RedMaestra?</p>
          <p>Somos una organización que por medio de alianzas y colaboraciones con empresas e instituciones, capacitamos integralmente a mujeres en oficios, generando diversas comunidades por especialidad las que finalmente conforman esta gran RED de mujeres técnico profesionales a lo largo del país.</p>
          <p style={{ fontWeight: 600 }}>¿A quienes buscamos?</p>
          <p>Convocamos a mujeres que desean capacitarse o necesitan una reconversión laboral para alcanzar su autonomía económica. También ayudamos a organizaciones públicas y privadas, que necesitan incrementar la mano de obra femenina técnica calificada en el mercado y se comprometan, a través de nosotras, a contribuir con los Objetivos de Desarrollo Sostenible de la ONU.</p>
        </div>

        <div id="nosotras_right" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <p style={{ textAlign: 'justify' }}>Finalmente existimos para que las mujeres sean protagonistas de sus vidas, potenciando sus capacidades, logrando así alcanzar todos juntos, un futuro integrador.</p>
        </div>
      </div>
    </>
  );
}

export default Nosotras;
