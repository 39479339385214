import React from 'react';
import Nosotra from '../components/2. Nosotras/nosotras';
import Objetivos from '../components/2. Objetivos/objetivos';
import Equipo from '../components/2. Equipo/equipo';
import Directorio from '../components/2. Directorio/directorio';

const componentMapping = {
  nosotras: Nosotra,
  objetivos: Objetivos,
  equipo: Equipo,
  directorio: Directorio
};

const Nosotras = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default Nosotras;
