import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './linktree.css';

// Directly import the images
import YT from '../../static/media-icons/logo-youtube.png';
import FB from '../../static/media-icons/logo-facebook.png';
import GM from '../../static/icons/logo-correo.png';
import IG from '../../static/media-icons/logo-instagram.png';
import LI from '../../static/media-icons/logo-linkedin.png';
import Icono2 from '../../static/icons/maestra2.png';
import Logo2 from '../../static/logo-blue-bg.png';

function Linktree() {
  const [lastTwoNoticias, setLastTwoNoticias] = useState([]);
  const [lastTestimonio, setLastTestimonio] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBlobData = async (fileName) => {
      try {
        const response = await fetch(`https://redmaestra.blob.core.windows.net/frontend/${fileName}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${fileName}`);
        }
        return await response.json();
      } catch (err) {
        console.error(`Error fetching ${fileName}:`, err);
        setError(true);
        return null;
      }
    };

    const loadNoticiasTestimonios = async () => {
      const noticiasData = await fetchBlobData('noticias.json');
      if (noticiasData) {
        setLastTwoNoticias(noticiasData.slice(-2).reverse());
      }

      const testimoniosData = await fetchBlobData('testimonios.json');
      if (testimoniosData && testimoniosData.length > 0) {
        setLastTestimonio(testimoniosData.slice(-1)[0]);
      }
    };

    loadNoticiasTestimonios();
  }, []);

  if (error) {
    return <div>Error loading content. Please try again later.</div>;
  }

  return (
    <>
      <Link to="/" className="lt-logo">
        <img src={Logo2} alt="Logo" loading="eager" />
      </Link>

      {lastTwoNoticias.map((noticia) => (
        <a key={noticia.id} href={`/noticias/${noticia.id}`} className="lt-a">
          <img src={noticia.image} className="lt-img" alt={noticia.title} />
          <h2 className="lt-h">{noticia.title}</h2>
        </a>
      ))}
      {lastTestimonio && (
        <a href={`/testimonios/${lastTestimonio.id}`} className="lt-a">
          <img src={lastTestimonio.image} className="lt-img lt-test" alt={lastTestimonio.title} />
          <h2 className="lt-h">{lastTestimonio.title}</h2>
        </a>
      )}

      <a href="https://www.youtube.com/watch?v=ynpBsqyeXH4&ab_channel=RedMaestra" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={YT} className="lt-img lt-ico" alt="YouTube" />
        <h2 className="lt-h">¿Qué es RedMaestra?</h2>
      </a>

      <a href="https://instagram.com/redmaestracl/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={IG} className="lt-img lt-ico" alt="Instagram" />
        <h2 className="lt-h">Instagram</h2>
      </a>

      <a href="https://www.facebook.com/redmaestracl/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={FB} className="lt-img lt-ico" alt="Facebook" />
        <h2 className="lt-h">Facebook</h2>
      </a>

      <a href="https://www.linkedin.com/company/redmaestra/" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={LI} className="lt-img lt-ico" alt="LinkedIn" />
        <h2 className="lt-h">LinkedIn</h2>
      </a>

      <a href="mailto:contacto@redmaestra.cl" target="_blank" rel="noopener noreferrer" className="lt-a">
        <img src={GM} className="lt-img lt-ico" alt="Correo" />
        <h2 className="lt-h">Correo</h2>
      </a>

      <a href="/" className="lt-a">
        <img src={Icono2} className="lt-img lt-ico" alt="Sitio Web" />
        <h2 className="lt-h">Sitio Web</h2>
      </a>
    </>
  );
}

export default Linktree;
