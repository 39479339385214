import React from 'react';
import './statistics.css';
import CountUp from 'react-countup';
import statisticsData from './statisticsData.json';

function Statistics() {

    const descriptions = [
        "Mujeres en la Comunidad",
        "Programas implementados",
        "Horas de capacitación",
        "Regiones impactadas",
        "Empresas confiaron"
    ];
    
    return (
        <>
            <ul>
                {statisticsData.map((item, index) => (
                    <li key={index}>
                        <p className="st_p">+<span> <CountUp start={0} end={item.count} /></span></p>
                        <p>{descriptions[index]}</p>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default Statistics;
