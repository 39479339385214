import React from 'react';
import './subscribe.css';
import media1 from '../../static/icons/icono-caja-herramientas-ama.png';
import media2 from '../../static/icons/icono-ladrillos-mora.png';

function Subscribe() {
    
 return (
     <>
     <div id="subscribes">
     <div id="leftbox">
            <ul>
                <li><img src={media1} alt="BoxL"/></li>
                <li><p>¿Eres <span>mujer </span>y quieres sumarte?</p></li>
                <li><a href="https://redmaestra.azurewebsites.net/api/formularios/agregar_comunidades/"><button className="yellowbut">Inscríbete</button></a></li>
            </ul>
        </div>
        <div id="rightbox">
            <ul>
                <li><img src={media2} alt="BoxR"/></li>
                <li><p>¿Eres <span>empresa </span>y quieres trabajar con nosotros?</p></li>
                <li><a href="https://redmaestra.azurewebsites.net/api/formularios/agregar_empresas/"><button className="purplebut">Agendar Reunión</button></a></li>
            </ul>
        </div>
     </div>
     </>
  );
}

export default Subscribe;
