import React from 'react';
import './prog_banner.css';
import Banner from '../../static/banner/prog_banner.png';

 function Prog_banner() {

 return (
     <>
        <img src={Banner} alt="Logo" loading="eager" />
         <div className="prog_ban_text">
           <p><span>Conoce nuestros programas</span></p>
           <a href="/programas">
            <span>Ver más</span>
            </a>
         </div>

     </>
  );
}

export default Prog_banner;
