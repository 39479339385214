import React from 'react';
import './equipo.css';

// Directly import the images
import image1 from '../../static/equipo/1.png';
import image2 from '../../static/equipo/2.png';
import image3 from '../../static/equipo/3.png';
import image4 from '../../static/equipo/4.png';
import image5 from '../../static/equipo/5.png';
import image6 from '../../static/equipo/6.png';
import image7 from '../../static/equipo/7.png';
import image8 from '../../static/equipo/8.png';

function Equipo() {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8];

  // Function to split array into chunks
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const groupedImages = chunkArray(images, 3);

  return (
    <>
      <h1>Nuestro Equipo</h1>
      <div id="nos_team">
        {groupedImages.map((group, index) => (
          <ul key={index}>
            {group.map((image, idx) => (
              <li key={idx}>
                <img src={image} alt={`Team Member ${index * 3 + idx + 1}`} />
              </li>
            ))}
          </ul>
        ))}
      </div>
    </>
  );
}

export default Equipo;
